import React from 'react'
import Link from 'gatsby-link'
import './style.scss'
import Img from "gatsby-image"

import {
  Card,
  CardBody,
  CardFooter,
  Container,
  Col,
  Row
} from 'reactstrap'

import {
  Button,
} from '../../components'

class SiteDocumentations extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      windowScrollTop: 0,
    }
  }

  render() {
    const documentations = this.props.data.remark.documentations
    const documentationsList = []
    documentations.forEach((data, i) => {
      const layout = data.documentation.frontmatter.layout
      const path = data.documentation.frontmatter.path
      const isCityRule = path.includes('reglements')
      const title = data.documentation.frontmatter.title
      if (layout === 'documentation' && path !== '/404/') {
        documentationsList.push(
          <Col key={Math.random()} lg={3} md={4} xs={12}>
            <Card className="card-pricing">
              <p className="card-category">{title}</p>
              <CardBody>
                <Img style={{maxHeight: 250, minHeight: 250}} imgStyle={{maxHeight: 250, minHeight: 250, objectFit: "contain"}} fluid={data.documentation.frontmatter.coverImage?.childImageSharp.fluid} alt={isCityRule ? 'Logo de la ville de ' + title : title} />
              </CardBody>
              <CardFooter>
                <Link to={path}>
                  <Button color="primary" round>
                    {isCityRule ? 'Voir la règlementation' : 'Voir la documentation'}
                  </Button>
                </Link>
              </CardFooter>
            </Card>
          </Col>
        )
      }
    })

    return (
      <Container>
        <Row></Row>
        <Row>{documentationsList}</Row>
      </Container>
    )
  }
}

export default SiteDocumentations
