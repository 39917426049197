import React from 'react'
import { graphql } from 'gatsby'
import { Container, Col, Row } from 'reactstrap'

import SiteDocumentations from '../../../components/SiteDocumentations'

import SEO from '../../../components/seo'

class reglements extends React.Component {
  render() {
    const seoData = {
      title: "Règlements d'irrigation municipaux",
      description: "Consultez les règlements d'irrigation municipaux",
      coverImage: '',
      path: this.props.location.pathname,
      schemaOrg: ''
    }

    return (
      <div>
        <SEO seoData={seoData} />
        <Container>
          <h1>Règlements d'irrigation municipaux</h1>
          <p>Pour votre information, nous mettons à votre disposition et les différents règlements municipaux par ville relatif à l'arrosage. Cependant, il est crucial de vérifier directement auprès de votre municipalité si des modifications ont été apportées à ces règlements. Les autorités locales peuvent réviser ces règlements périodiquement pour répondre aux besoins changeants de la communauté et pour garantir une gestion efficace de l’eau.</p>
          <Row>
            <Col lg={2} md={2} xs={6}>
            </Col>
            <Col lg={10} md={10} xs={12} />
          </Row>
          <SiteDocumentations {...this.props} />
        </Container>
      </div>
    )
  }
}

export default reglements

export const reglementsDocumentationQuery = graphql`
  query ReglementsDocumentation {
    site {
      meta: siteMetadata {
        title
        description
        url: siteUrl
        author
      }
    }
    remark: allMarkdownRemark(
      filter: { frontmatter: { vendor: { eq: "Reglements" } } }
    ) {
      documentations: edges {
        documentation: node {
          id
          html
          frontmatter {
            layout
            title
            path
            coverImage {
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`
